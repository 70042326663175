.text-page {

    padding-bottom: 80px;

    h1 {
        font-size: 36px;
        font-weight: bold;
    }

    p {
        font-size: 18px;
    }
    
}

@media all and (max-width: 480px) {
    .text-page {

       
        h1 {
            font-size: 24px;
            font-weight: bold;
        }
    
        p {
            font-size: 16px;
        }
    }
}