body {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: normal;
    font-style: normal;

    color: #FFF;
    font-size: 16px;

    background: #333333;
    min-width: 1140px;
}

@media all and (max-width: 480px) {
    body {
        min-width: auto;
    }
}

.section {

    .content {
        width: 1140px;
        margin: 0px auto;
        box-sizing: border-box;
    }
}

@media all and (max-width: 480px) {
    
    .section {

        .content {
            width: 100%;
            margin: 0;
            padding: 0 12px;
        }
    }

}

.button {
    background: #8338EC;
    padding: 8px;
    color: #FFFFFF;

    border: none;
    outline: none;

    text-decoration: none;
    cursor: pointer;

    transition: transform 0.3s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }

    &.white {
        background: #E3DFF7;
        color: #333;
    }
}

.input {

    padding: 24px 30px;

    border: none;
    border-radius: 15px;
    box-sizing: border-box;
    color: #333;
    outline: none;

    &::placeholder {
        color: rgba(0, 0, 0, .45);
    }

    &:hover {
        outline: 1px solid #333;
    }
    
}

a {
    color: #FFF;
}