.footer {

    background: #404040;

    .content {
        width: 1140px;
        margin: 0 auto;
        box-sizing: border-box;

        display: flex;
        justify-content: space-between;

        padding: 16px;
    }

    .links {
        display: flex;

        a {
            margin-left: 16px;
            color: #FFF;
            text-decoration: none;
        }
    }
}

@media all and (max-width: 480px) {
    .footer {
        .content {
            width: 100%;
            flex-direction: column;
        }

        .sign {
            text-align: center;
        }

        .links {
            margin-top: 24px;
            justify-content: center;
        }
    }
}