.top-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 1140px;
    margin: 0 auto;
    padding: 27px;

    box-sizing: border-box;

    &__logo {

        display: flex;
        align-items: center;

        color: #FFF;
        text-decoration: none;

        & > img {
            margin-right: 12px;
        }
    }

    &__links {

        list-style: none;
        padding: 0;
        margin: 0;

        display: flex;

        li {
            margin-right: 16px;
        }

        a {
            color: #FFFFFF;
            text-decoration: none;
        }
    }

    &__right {
        display: flex;
        align-items: center;
    }

    &__log-in {
        color: #FFFFFF;
        margin-right: 16px;
        text-decoration: none;
    }
}

@media all and (max-width: 480px) {
    .top-navigation {
        width: 100%;

        flex-direction: column;

        &__links {
            margin-top: 24px;
        }

        &__right {
            margin-top: 24px;
        }
    }
}