.alert {
    background: #72EC38;
    color: white;
    font-weight: bold;
    padding: 24px 0;
    text-align: center;

    &.danger {
        background-color: #EC384E;
    }
}