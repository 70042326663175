.first-section {
  .content {
    aspect-ratio: 1.78;
    background-image: url("../../public/img/block-1-background.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .slug {
    color: #8338EC;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: normal;

    margin: 0;

    background: rgba(#FFFFFF, 40%);
    padding: 2px 4px;
  }

  .main-text {
    margin: 16px 0 0;
    font-size: 24px;
    line-height: 48px;
    text-align: center;
  }

  .form {
    position: relative;
  }

  .label {
    font-size: 18px;
  }

  .input {

    display: block;
    border: 1px solid #8338EC;
    background: #333;
    width: 748px;
    color: #FFF;
    margin-top: 12px;
    padding: 16px;

    border-radius: 0;

    &::placeholder {
      color: #E6E6E6;
    }

  }

  .input-button {

    position: absolute;
    right: 8px;
    top: 42px;

    position: absolute;
    background: #8338EC;
    border: none;
    text-transform: uppercase;
    padding: 8px 16px;

    color: #FFF;
  }
}

@media all and (max-width: 480px) {

  .first-section {

    .content {
      aspect-ratio: 1.2;
    }


    .slug {
      font-size: 16px;
    }

    .main-text {
      font-size: 16px;
      line-height: 28px;
    }

    .form {
      position: relative;
      width: 100%;
      margin-top: 24px;
    }

    .label {
      font-size: 16px;
    }

    .input {
      width: 100%;
    }

    .input-button {
      top: 39px;
    }
  }

}

.services {

  margin-top: 60px;

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    text-align: center;
  }

  p {
    margin: 24px 0 0;
    text-align: center;
    line-height: 32px;
  }
}

@media all and (max-width: 480px) {
  .services {
    .content {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto;
      gap: 32px;
    }
  }
}

.platform {

  margin-top: 60px;

  .content {
    aspect-ratio: 1.78;
    background-image: url("../../public/img/block-2-background.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .slug {
    color: #8338EC;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: normal;

    margin: 0;

    background: rgba(#FFFFFF, 40%);
    padding: 2px 4px;
  }

  .title {
    margin: 16px 0 0;
    font-size: 24px;
    line-height: 48px;
    text-align: center;
  }

  .desc {
    margin: 24px 0 0;
    font-size: 24px;
    line-height: 48px;
    text-align: center;
  }

  .button {
    margin-top: 24px;
  }
}

@media all and (max-width: 480px) {
  .platform {

    margin-top: 60px;

    .content {
      aspect-ratio: 1;
    }

    .slug {
      font-size: 18px;
    }

    .title {
      font-size: 18px;
      line-height: 24px;
    }

    .desc {
      font-size: 18px;
      line-height: 28px;
    }
  }
}


.faq {

  margin-top: 60px;
  margin-bottom: 60px;

  h2 {
    font-size: 24px;
    font-weight: bold;

    margin: 0 0 24px;

    text-align: center;
  }

  .list {
    display: block;
    list-style: none;
    padding: 0;
    margin-top: 24px;
  }

  .list-content {
    margin: 0 0 32px;

    h3 {
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      margin: 0;
    }

    p {
      margin: 16px 0 0;
      line-height: 32px;
    }
  }
}

@media all and (max-width: 480px) {
  .faq {

    h2 {
      font-size: 18px;
    }


    .list-content {

      h3 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        margin: 0;
      }
    }
  }
}